@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  color: black;
  background-color: #061237;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #1aac83;
  --error: #e7195a;
}

header {
  background-color: aqua;
}

header .container {
  text-align: center;
  align-items: center;
}

.navbar {
  border-bottom: rgb(141, 141, 141) solid 1px;
}

.nav-creds-can {
  display: flex;
  padding: 0;
}

.top-navi {
  display: flex;
  justify-content: space-around;
}

.banner {
  margin-top: 0;
}

.welcome {
  display: flex;
  flex-wrap: wrap;
}

.page-contents {
  padding: 67px 0 100px;
  min-height: 100vh;
  background-color: gainsboro;
}

.page-links {
  display: flex;
  align-items: center;
}

.link-btn {
  padding: 0 5px;
  color: black;
  text-decoration: none;
}

.logout-btn {
  max-width: 50px;
  padding: 3px 2px;
  font-size: 8pt;
  text-align: center;
  background-color: #6c757d;
}

.pname {
  font-size: 9pt;
}

.rh-logo,
.rh-topnav-logo {
  max-width: 225px;
  margin: 0;
}

.MNavi {
  display: none;
}

.mnav-logout-btn {
  background-color: #061237;
}

.creds-link {
  width: 45%;
}

.creds-btn {
  margin: 0 5px;
  padding: 0 6px;
  background-color: goldenrod;
  border: gainsboro solid 1px;
}

.creds-btn:hover {
  background-color: gainsboro;
  border: gainsboro solid 1px;
  color: black;
}

.creds-btn:active {
  background-color: gainsboro;
  border: gainsboro solid 1px;
  color: black;
}

.creds-btn:focus {
  background-color: gainsboro !important;
  border: gainsboro solid 1px !important;
  color: black;
}

.dropdown-toggle::after {
  content: none !important;
}

.landing-page {
  padding-top: 57px;
}

.carousel-item,
.showcase-img {
  object-fit: cover;
  object-position: center;
  height: 60vh;
  overflow: hidden;
}

.carousel-header {
  font-size: 18pt;
  color: whitesmoke;
}

.carousel-caption {
  overflow: hidden;
  object-position: center;
  z-index: 5;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.349);
  max-width: 700px;
  margin: 0 auto;
}

.landing-showcase,
.carousel-item,
.showcase-img {
  max-height: 60vh;
  border-bottom: rgb(141, 141, 141) solid 1px;
}

.showcase-img {
  width: 100%;
}

.team-member-info-can {
  border-radius: 5px;
}

.team-member-name {
  margin: 0 auto;
  font-size: 13pt;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.runner-what,
.runner-mission,
.runner-team,
.runner-service,
.runner-review,
.runner-contact {
  display: flex;
  padding: 100px 25px;
  min-height: 50vh;
}

.runner-what-l {
  text-align: justify;
  padding: 25px;
  width: 60%;
}
.runner-what-r {
  display: flex;
  width: 40%;
  align-items: center;
}

.runner-mission {
  background-color: #f8f9fa;
}

.runner-mission-l {
  display: flex;
  width: 45%;
  margin: 0 auto;
  align-items: center;
}

.runner-mission-r {
  text-align: justify;
  padding: 25px;
  width: 50%;
}

.runner-mission-statement {
  font-size: 15pt;
}

.runner-team {
  background-color: #061237;
  display: flex;
  flex-direction: column;
  color: aliceblue;
}

.runner-team-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.runner-service {
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
}

.all-services-can {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.services-can {
  width: 32%;
  border-radius: 30px;
  background-color: gainsboro;
  border: 1px solid #061237;
  box-shadow: 3px 3px 3px #111;
}

.service-img-can {
  height: 60%;
  min-height: 275px;
  border-bottom: #061237 3px solid;
}

.service-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 29px;
  border-top-right-radius: 29px;
}

.service-divider {
  min-width: 1%;
}

.service-desc {
  min-height: 50px;
  height: 40%;
  margin: 25px;
  font-size: 20pt;
  font-weight: 600;
  text-align: center;
}

.all-cases-can {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.cases-can {
  width: 32%;
  background-color: whitesmoke;
  border-radius: 30px;
  border: 1px solid #061237;
  box-shadow: 3px 3px 3px #111;
  min-height: 700px;
}

.case-img-can {
  height: 60%;
  min-height: 275px;
  border-bottom: #061237 3px solid;
}

.case-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 29px;
  border-top-right-radius: 29px;
}

.case-divider {
  min-width: 1%;
}

.case-desc {
  min-height: 50px;
  margin: 25px;
  font-size: 20pt;
}

.runner-review {
  background-color: goldenrod;
  display: flex;
  flex-direction: column;
}

.runner-contact {
  background-color: gainsboro;
  justify-content: space-around;
}

.runner-contact-l {
  background-color: gainsboro;
  padding: 40px;
}

.runner-contact-r {
  display: flex;
  justify-content: center;
}

.group-runner-can {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.runner-link {
  color: goldenrod;
  font-size: 14pt;
  font-weight: 600;
}

.runner-img {
  width: 100%;
  border-radius: 25px;
}

.team-img {
  object-fit: cover;
  object-position: center;
  border-bottom: goldenrod 1px solid;
  height: 75%;
}

.subsec-title {
  text-align: center;
  font-weight: 600;
  font-size: 30pt;
  padding-bottom: 50px;
}

.team-member-card {
  margin: 25px;
  width: 20rem;
  border: goldenrod 1px solid;
  background-color: gainsboro;
  box-shadow: 5px 3px 3px #111;
}

.contact-img {
  border-radius: 25px;
  width: 80%;
}

.card-body {
  background-color: gainsboro;
  border-radius: 15px;
}

.footy-logo {
  max-height: 100px;
}

.footy-border {
  border-bottom: rgb(141, 141, 141) solid 1px;
}

.footy-links {
  display: block;
  color: #6c757d;
}

.footy {
  background-color: #f8f9fa;
  min-height: 20vh;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f9fa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #061237;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
}

@media screen and (max-width: 800px) {
  .top-navi {
    display: none;
  }

  .topnav-contents {
    justify-content: center !important;
  }

  .rh-logo {
    max-width: 175px;
  }

  .MNavi {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    padding: 0;
    z-index: 500;
    min-height: 50px;
    border-top: rgb(141, 141, 141) solid 1px;
  }

  .bott-navi {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .nav-link {
    height: 100%;
  }

  .bttm-link-btn {
    display: flex;
    width: 100%;
    min-height: 40px;
    color: #061237;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-size: 15pt;
  }

  .bttm-link-btn:hover {
    color: goldenrod;
  }

  .profile-options-btn {
    color: #061237;
    background-color: #f8f9fa;
    padding: 0 12px;
  }

  .dd-profile-btn,
  .dropdown-toggle {
    background-color: #f8f9fa !important;
    padding: 0;
    border: none;
  }

  .dropdown-menu[data-bs-popper] {
    left: -70%;
  }

  .mnav-logout-btn {
    max-height: 30px;
    font-size: 9pt;
    width: 100%;
  }

  .mnav-login {
    border-right: rgb(141, 141, 141) solid 1px;
  }

  .mnav-join {
    border-left: rgba(141, 141, 141, 0.329) solid 1px;
  }

  .carousel-item,
  .showcase-img {
    object-fit: cover;
    object-position: center;
    height: 50vh;
    overflow: hidden;
  }

  .carousel-caption {
    overflow: hidden;
    object-position: center;
    z-index: 5;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.349);
  }

  .landing-showcase,
  .carousel-item,
  .showcase-img {
    max-height: 50vh;
    border-bottom: rgb(141, 141, 141) solid 1px;
  }

  .showcase-img {
    width: 100%;
  }

  .footy-logo {
    max-height: 75px;
  }

  .footy-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footy-links {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rh-footer-can {
    display: flex;
    flex-direction: column;
  }

  .footy {
    padding-bottom: 60px;
  }

  .signup-form-container,
  .switch-section-container {
    width: 80% !important;
    min-width: 280px;
  }

  .switch-section p {
    text-align: center;
    font-size: 11pt;
  }

  .logo {
    margin: 15px auto !important;
  }

  .runner-overflow {
    display: none;
  }

  .runner-what,
  .runner-mission,
  .runner-team,
  .runner-service,
  .runner-review,
  .runner-contact {
    flex-direction: column;
    align-items: center;
    padding: 100px 25px;
  }

  .runner-what-l {
    text-align: justify;
    width: 100%;
    padding: 0 0 50px;
  }
  .runner-what-r {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .runner-mission-l {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .runner-mission-r {
    text-align: justify;
    padding: 25px;
    width: 100%;
  }

  .services-can {
    width: 90%;
    margin: 25px auto;
    background-color: gainsboro;
  }

  .service-divider {
    display: none;
  }

  .service-img-can {
    height: 60%;
    max-height: 285px;
  }

  .cases-can {
    width: 90%;
    margin: 25px auto;
    background-color: whitesmoke;
    min-height: 400px;
  }

  .case-divider {
    display: none;
  }

  .case-img-can {
    height: 60%;
    max-height: 285px;
  }

  .service-desc {
    min-height: 50px;
    height: 40%;
    margin: 25px;
    font-size: 25pt;
    text-align: center;
  }
}

@media screen and (max-width: 1250px) {
  .case-desc {
    min-height: 50px;
    margin: 50px;
    font-size: 15pt;
  }
}

@media screen and (max-width: 1000px) {
  .case-desc {
    min-height: 50px;
    margin: 30px;
    font-size: 15pt;
  }
}
